<template>
  <v-dialog width="500" v-model="dialog">
    <template v-slot:activator="{ on, attrs }">
      <v-btn text small icon v-bind="attrs" v-on="on">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on">mdi-comment-plus-outline</v-icon>
          </template>
          <span>Add New Comment</span>
        </v-tooltip>
      </v-btn>
    </template>

    <v-card>
      <v-card-title>Write Comment</v-card-title>

      <v-card-text>
        <v-textarea v-model="comment" outlined class="fill-height" hide-details></v-textarea>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn color="primary" @click="saveComment()">Submit</v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="dialog = false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'add-comment',
  props: ["mjo_header_id"],
  data() {
    return {
      loading: null,
      dialog: null,
      comment: "",
    }
  },
  methods: {
    close() {
      this.dialog = false;
      this.$emit("callback");
    },

    async saveComment() {
      if (this.comment.toString().trim().length === 0) return alert('Can\'t empty!');
      this.loading = true;
      this.showLoadingOverlay(true);
      var formData = new FormData();
      formData.append("mjo_header_id", this.mjo_header_id);
      formData.append("comment", this.comment);
      await this.$axios.post("mjo/comment/save", formData)
        .then((res) => {
          this.loading = false;
          this.dialog = false;
          this.showLoadingOverlay(false);
          this.showAlert(res.data.status, res.data.message);
          this.comment = ""

          this.close();
        })
        .catch((error) => {
          this.loading = false
          this.dialog = false;
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);

        });
    },
  },

  mounted() {
    this.comment = ""
  }
}
</script>